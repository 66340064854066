import React from 'react';
import './Header.css';
const Header = () => {
    return (
        <div className='Header'>
            <span>
                <img className="image" src="images/logo.png" alt="Company" />
            </span>
        </div>
    );
};

export default Header;
