import React from 'react';
import Form from './components/Form';
import Header from './components/Header';
import './App.css'; // Import your main CSS file if you have one
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Optional for Bootstrap's JS functionality

function App() {
  return (
    <div className="col-11 col-xl-4 col-lg-5 col-md-8 mx-auto mt-3 hansFormMainContainer flower-border pb-4" style={{ maxWidth: "500px", minHeight: '300px', paddingTop: '10px', paddingBottom: '10px' }} >
      <Header />
      <Form />
    </div>
  );
}
export default App;
